import Service, { inject as service } from '@ember/service';

export default class PurchaseOriginService extends Service {
  @service localSettings;

  STORAGE_KEY = 'purchase-origin';

  setOrigin(source) {
    this.localSettings.set(this.STORAGE_KEY, source);
  }

  getOrigin() {
    return this.localSettings.get(this.STORAGE_KEY, 'appointments');
  }

  setPurchaseOrigin(transition) {
    const prevRoute = transition.from?.name;

    const source = !prevRoute
      ? 'appointments'
      : prevRoute.startsWith('accounts.account.purchase.new')
        ? 'purchase'
        : prevRoute.startsWith('accounts.account.sales.till-transactions')
          ? this.getOrigin()
          : 'appointments';

    this.setOrigin(source);
  }

  getPostPurchaseRoute() {
    return this.getOrigin() === 'purchase'
      ? 'accounts.account.purchase.new'
      : 'accounts.account.appointments';
  }
}
