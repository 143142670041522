import Service, { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { compareSemVerGte } from 'my-phorest/utils/compare-semver';
import config from 'my-phorest/config/environment';

const RECEIPT_PRINTER_CONFIG_KEY = 'my-phorest:electron:receipt-printer';

export default class ElectronAppService extends Service {
  @service('browser/localStorage') localStorage;
  @service('browser/window') window;
  @service intl;
  @service pendo;
  @service printerSetup;
  @service receiptSettings;
  @service revisionKey;
  @service session;

  @tracked isElectronPrinting;

  constructor() {
    super(...arguments);

    if (this.api?.pendoTrackEvent) {
      this.api.pendoTrackEvent((pendoEventName, properties) => {
        this.pendo.trackEvent(pendoEventName, properties);
      });
    }
  }

  get isRunningInElectron() {
    return !!this.window.electronAPI;
  }

  get api() {
    return this.window.electronAPI;
  }

  launchSwing() {
    this.api?.launchSwing?.();
  }

  async canLaunchSwing() {
    return await this.api?.canLaunchSwing?.();
  }

  async getElectronAppVersion() {
    return await this.api?.getAppVersion?.();
  }

  async getVersion() {
    const electronAppVersion = await this.getElectronAppVersion();
    let revision = 'local';

    const { deployRevision, UNSPECIFIED_REVISION_KEY } = config.APP;
    if (deployRevision !== UNSPECIFIED_REVISION_KEY) {
      revision = deployRevision;
    }

    let version = `${electronAppVersion}-${revision.slice(0, 6)}`;

    if (config.deployTarget && config.deployTarget !== 'production') {
      version = `${version} (${config.deployTarget})`;
    }

    return version;
  }

  async getPrinters() {
    return this.api?.getPrinters?.();
  }

  notifyDidSetupSentry(info) {
    this.api?.notifyDidSetupSentry?.(info);
  }

  async openCashDrawer({ onWarningCb } = {}) {
    const electronAppVersion = await this.getElectronAppVersion();
    const isNewCashDrawerApiElectron = compareSemVerGte(
      electronAppVersion,
      '10.9.0'
    );

    let printerName = this.receiptPrinterName;

    if (this.session.terminalId && isNewCashDrawerApiElectron) {
      const terminal = await this.receiptSettings.fetchTerminal();
      const virtualComPort = terminal?.receiptPrinterConfig?.port || null;

      this.api?.openCashDrawer?.(
        {
          printerName,
          virtualComPort,
        },
        (warning) => {
          if (typeof onWarningCb === 'function') {
            onWarningCb(warning);
          }
        }
      );
    } else {
      this.api?.openCashDrawer?.(printerName);
    }
  }

  async printReceipt(url, callbacks, options) {
    let printerName = this.receiptPrinterName;

    if (isEmpty(printerName)) {
      printerName = await this.printerSetup.selectReceiptPrinter();

      if (isEmpty(printerName)) {
        if (typeof callbacks?.failureCb === 'function') {
          const error = new Error('No printer selected');
          callbacks.failureCb(error);
        }
        return;
      }

      this.saveReceiptPrinter(printerName);
    }

    const printURL = new URL(url);

    if (this.revisionKey.key) {
      printURL.searchParams.append('revision', this.revisionKey.key);
    }

    this.isElectronPrinting = true;

    this.api?.print?.(
      printerName,
      printURL.toString(),
      () => {
        this.isElectronPrinting = false;

        if (typeof callbacks?.successCb === 'function') {
          callbacks.successCb();
        }
      },
      options
    );
  }

  shutdown() {
    this.api?.shutdown?.();
  }

  minimize() {
    this.api?.minimize?.();
  }

  launchExternalURL(url) {
    this.api?.launchExternalURL?.(url);
  }

  saveReceiptPrinter(printerName) {
    this.receiptPrinterName = printerName;
    this.api?.saveReceiptPrinter?.(printerName);
  }

  async loadReceiptPrinter() {
    let printerName = await this.api?.loadReceiptPrinter?.();

    this.receiptPrinterName = printerName;
  }

  get receiptPrinterName() {
    return this.localStorage.getItem(RECEIPT_PRINTER_CONFIG_KEY);
  }

  set receiptPrinterName(value) {
    if (value && value.trim?.() !== '') {
      this.localStorage.setItem(RECEIPT_PRINTER_CONFIG_KEY, value);
    } else {
      this.localStorage.removeItem(RECEIPT_PRINTER_CONFIG_KEY);
    }
  }
}
