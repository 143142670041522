import Service, { service } from '@ember/service';
import { queryManager } from 'ember-apollo-client';

import staffMemberQuery from 'my-phorest/gql/queries/staff-member.graphql';

const ENTITY_LIST_QUERIES_MAP = {
  appointmentPromptSettings: 'branchAppointmentPromptSettings',
  branch: 'branches',
  business: 'business',
  clientCategory: 'clientCategories',
  clientSource: 'clientSources',
  machine: ['machines', 'machinesCalendar', 'roomsCalendar', 'staffCalendar'],
  openingHours: [
    'branchTimeSlots',
    'machinesCalendar',
    'roomsCalendar',
    'staffCalendar',
  ],
  productCategory: 'productCategories',
  room: ['rooms', 'machinesCalendar', 'roomsCalendar', 'staffCalendar'],
  rosters: ['machinesCalendar', 'roomsCalendar', 'staffCalendar'],
  service: ['services', 'serviceCategories', 'qualifiedStaffMembers'],
  serviceCategory: ['serviceCategories', 'chainServiceCategories'],
  staff: ['staffConnection', 'staffCalendar', 'qualifiedStaffMembers'],
  staffCategory: 'staffCategories',
};

export default class ApolloCache extends Service {
  @service session;
  @queryManager apollo;

  get cache() {
    return this.apollo.apollo.client.cache;
  }

  getEntityName(resourceType, id) {
    const type = resourceType.charAt(0).toUpperCase() + resourceType.slice(1);
    return `${type}:${id}`;
  }

  async handleEmbeddedAppSignalForCacheInvalidation(data) {
    if (data.type === 'entity') {
      const entityName = this.getEntityName(data.resourceType, data.id);
      const cacheData = this.cache.extract();
      const isCached = !!cacheData[entityName];

      if (isCached) {
        this.cache.evict({ id: entityName });
        await this.reFetchSingleEntity(data.resourceType, data.id);
      }
    }

    this.invalidateList(data.resourceType);
    this.cache.gc();

    if (this.shouldUpdateSession(data.resourceType, data.id)) {
      await this.session.reloadCurrentUserSession();
    }
  }

  invalidateList(type) {
    const fieldName = ENTITY_LIST_QUERIES_MAP[type];

    if (fieldName) {
      const fieldNames = Array.isArray(fieldName) ? fieldName : [fieldName];

      fieldNames.forEach((fieldName) => {
        this.cache.evict({ id: 'ROOT_QUERY', fieldName });
      });
    }
  }

  async reFetchSingleEntity(type, id) {
    if (type === 'staff') {
      await this.apollo.query(
        {
          query: staffMemberQuery,
          variables: { id },
        },
        type
      );
    }
  }

  shouldUpdateSession(type, id) {
    switch (type) {
      case 'accessLevel':
        return this.session.currentUser?.staffMembers.some(
          (staffMember) => staffMember.accessLevel.id === id
        );
      case 'staff':
        return this.session.currentUser?.staffMembers.some(
          (staffMember) => staffMember.id === id
        );
      case 'user':
        return this.session.userId === id;
      case 'business':
      case 'branch':
        return true;
      default:
        return false;
    }
  }
}
