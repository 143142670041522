export function findServiceGroupDefaultPrimaryService(availableServices) {
  const cutAndStylingServices = availableServices.filter((s) => {
    return s.category?.internetServiceCategory?.code === 'CUT_STYLING';
  });

  if (cutAndStylingServices.length === 1) return cutAndStylingServices[0];

  const eligibleServices =
    cutAndStylingServices.length > 1
      ? cutAndStylingServices
      : availableServices;

  const mostExpensiveServices = eligibleServices.reduce(
    (currentServices, newService) => {
      if (currentServices.length === 0) return [newService];

      const currentPrice = Number(currentServices[0].price.amount);
      const newPrice = Number(newService.price.amount);

      if (currentPrice === newPrice) return [...currentServices, newService];

      return currentPrice > newPrice ? currentServices : [newService];
    },
    []
  );

  if (mostExpensiveServices.length === 1) {
    return mostExpensiveServices[0];
  }

  const longestServices = mostExpensiveServices.reduce(
    (currentServices, newService) => {
      if (currentServices.length === 0) return [newService];

      const currentDuration = currentServices[0].durationMins;
      const newDuration = newService.durationMins;

      if (currentDuration === newDuration)
        return [...currentServices, newService];

      return currentDuration > newDuration ? currentServices : [newService];
    },
    []
  );

  return longestServices[0];
}
