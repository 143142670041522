import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ConfirmDialogService extends Service {
  @tracked
  isOpen = false;

  @tracked
  options = {};

  ask(options = {}) {
    this.options = options;
    this._open();
    return new Promise((resolve) => {
      this._confirm = resolve;
    });
  }

  confirm() {
    this._confirm(true);
    this._close();
  }

  deny() {
    this._confirm(false);
    this._close();
  }

  _open() {
    this.isOpen = true;
  }

  _close() {
    this.isOpen = false;
  }
}
