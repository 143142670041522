import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { queryManager } from 'ember-apollo-client';
import staffCategoriesQuery from 'my-phorest/gql/queries/staff-categories.graphql';
import { fetchAllPaginatedResults } from 'my-phorest/utils/graphql';
import { action } from '@ember/object';
import serviceWithStaffPricesQuery from 'my-phorest/gql/queries/service-with-staff-prices.graphql';
import chainServiceWithStaffPricesQuery from 'my-phorest/gql/queries/chain-service-with-staff-prices.graphql';
import { task } from 'ember-concurrency';

export default class StaffCategoryPricesSlideOverService extends Service {
  @queryManager apollo;
  @service notifications;
  @service intl;

  @tracked fallbackPrice;
  @tracked staffCategories;
  @tracked staffCategoryPricesByCategoryId;
  @tracked emptyStaffCategoryPrices = false;
  underlyingStaffCategoryPricesByCategoryId;
  parent;

  @tracked isOpen = false;

  async openWithStaffCategoryPrices(
    parent,
    staffCategoryPrices = [],
    underlyingStaffCategoryPrices = [],
    fallbackPrice
  ) {
    this.isOpen = true;
    this.parent = parent;
    this.fallbackPrice = fallbackPrice;
    this.staffCategories = await this.loadStaffCategories();
    this.staffCategoryPricesByCategoryId =
      this.buildStaffCategoryPriceObject(staffCategoryPrices);
    this.underlyingStaffCategoryPricesByCategoryId =
      this.buildStaffCategoryPriceObject(underlyingStaffCategoryPrices);
    this.emptyStaffCategoryPrices = staffCategoryPrices.length <= 0;
  }

  buildStaffCategoryPriceObject(prices) {
    return Object.fromEntries(
      prices.map((scp) => [scp.staffCategoryId, scp.price])
    );
  }

  @action
  async copyServicePrices(isChainService) {
    const service = await this.fetchService.perform(
      isChainService ? this.parent.chainService.id : this.parent.service.id,
      isChainService
    );

    if (service.staffCategoryPrices.length === 0) {
      this.notifications.failure(
        this.intl.t('staff-category-prices-slide-over.copy-notification-title'),
        this.intl.t(
          'staff-category-prices-slide-over.copy-notification-failure-description'
        )
      );
    } else {
      service.staffCategoryPrices.forEach((scp) =>
        this.changePrice(scp.staffCategoryId, scp.price.amount)
      );

      this.notifications.success(
        this.intl.t('staff-category-prices-slide-over.copy-notification-title'),
        this.intl.t(
          'staff-category-prices-slide-over.copy-notification-success-description'
        )
      );
    }
  }

  @task
  *fetchService(serviceId, isChainService) {
    return yield this.apollo.watchQuery(
      {
        query: isChainService
          ? chainServiceWithStaffPricesQuery
          : serviceWithStaffPricesQuery,
        variables: { id: serviceId },
      },
      isChainService ? 'chainService' : 'service'
    );
  }

  close() {
    this.isOpen = false;
  }

  get allStaffCategoriesWithPrice() {
    return this.staffCategories?.map((sc) => {
      return {
        staffCategory: sc,
        price: this.staffCategoryPricesByCategoryId?.[sc.id],
        underlyingPrice:
          this.underlyingStaffCategoryPricesByCategoryId?.[sc.id],
      };
    });
  }

  get staffCategoryPrices() {
    return Object.entries(this.staffCategoryPricesByCategoryId).map(
      ([staffCategoryId, price]) => {
        return {
          staffCategoryId,
          price,
        };
      }
    );
  }

  @action
  changePrice(staffCategoryId, price) {
    const newPrices = { ...this.staffCategoryPricesByCategoryId };
    delete newPrices[staffCategoryId];

    if (price != null) {
      newPrices[staffCategoryId] = price;
    }

    this.staffCategoryPricesByCategoryId = newPrices;
  }

  async loadStaffCategories() {
    return fetchAllPaginatedResults(
      this.apollo,
      {
        query: staffCategoriesQuery,
        variables: {
          first: 500,
        },
      },
      'staffCategories'
    );
  }
}
