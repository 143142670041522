import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { queryManager } from 'ember-apollo-client';
import { trackedTask } from 'reactiveweb/ember-concurrency';
import { task, waitForProperty } from 'ember-concurrency';
import tillsQuery from 'my-phorest/gql/queries/tills.graphql';
import { fetchAllPaginatedResults } from 'my-phorest/utils/graphql';
import { registerDestructor } from '@ember/destroyable';

export default class TillLoaderService extends Service {
  @service eventPropagator;
  @service localSettings;
  @service session;
  @service terminalLoader;
  @service swingBridge;

  @queryManager apollo;

  @tracked currentTillId;
  @tracked showTillSelectorDialog = false;

  availableTillsInstance = trackedTask(this, this.getTillsTask);
  #branchChangeHandler;

  constructor() {
    super(...arguments);

    this.#loadTillIdFromLocalSetting();
    this.#setupBranchChangeHandler();
  }

  #setupBranchChangeHandler() {
    this.#branchChangeHandler = this.#loadTillIdFromLocalSetting.bind(this);
    this.eventPropagator.subscribe('branchChange', this.#branchChangeHandler);
    registerDestructor(this, this.#teardownBranchChangeHandler.bind(this));
  }

  #teardownBranchChangeHandler() {
    this.eventPropagator.unsubscribe('branchChange', this.#branchChangeHandler);
  }

  #loadTillIdFromLocalSetting() {
    this.currentTillId = this.localSettings.get('tillId');
  }

  saveCurrentTillId(tillId) {
    this.showTillSelectorDialog = false;
    this.currentTillId = tillId;
    this.localSettings.set('tillId', tillId);
  }

  resetCurrentTillId() {
    this.currentTillId = null;
    this.localSettings.remove('tillId');
  }

  @task
  *getCurrentTillIdTask() {
    if (this.currentTillId) return this.currentTillId;

    // use till of provided terminal
    if (this.session.terminalId && this.swingBridge.isEmbeddedInSwing) {
      let terminal = yield this.terminalLoader.getTerminal.perform(
        this.session.terminalId
      );
      if (terminal.tillId) {
        this.currentTillId = terminal.tillId;
        return terminal.tillId;
      }
    }

    // if there is only one till, use that
    const tills = yield this.getTillsTask.perform();
    if (tills.length === 1) {
      const till = tills[0];
      this.saveCurrentTillId(till.id);
      return this.currentTillId;
    }

    // show dialog asking user for the current till
    this.showTillSelectorDialog = true;

    yield waitForProperty(this, 'currentTillId');

    return this.currentTillId;
  }

  @task
  *getTillsTask() {
    const tills = yield fetchAllPaginatedResults(
      this.apollo,
      {
        query: tillsQuery,
        variables: {
          first: 50,
          branchId: this.session.branch.id,
        },
      },
      'tills'
    );

    return tills?.sort((a, b) => a.name?.localeCompare(b.name));
  }
}
