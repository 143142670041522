import { format as formatDate } from 'my-phorest/utils/local-date-helpers';
import {
  convertToLocalDate,
  convertToLocalTime,
  format as formatDatetime,
} from 'my-phorest/utils/local-datetime-helpers';

export default function formatTillSessionDate(
  intl,
  tillSession,
  fieldKey = 'startDateTime'
) {
  const date = tillSession[fieldKey];

  if (!date) {
    return null;
  }

  if (convertToLocalTime(date) === '00:00:00.000') {
    return formatDate(convertToLocalDate(date), intl, 'long');
  } else {
    return formatDatetime(date, intl, 'short');
  }
}
