/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';
import { trackedTask } from 'reactiveweb/ember-concurrency';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import phorestLogo from 'my-phorest/assets/images/phorest-logo.svg';
import config from 'my-phorest/config/environment';

const ACTIVE_INTEGRATIONS = {
  SALON_INTERACTIVE: {
    key: 'SALON_INTERACTIVE',
  },
  AVEDA_V2: {
    key: 'AVEDA_V2',
    authUrl: config.aveda.authUrl,
  },
};

export default class IntegrationsAuthenticateSupplierComponent extends Component {
  @queryManager apollo;
  @service intl;
  @service('browser/session-storage') sessionStorage;
  @service errorHandler;
  @service('browser/window') window;

  @tracked error;

  phorestLogoSrc = phorestLogo;

  authenticateSupplier = trackedTask(this, this.authenticateSupplierTask);

  @task
  *authenticateSupplierTask() {
    let { supplier, businessId, branchId, code, state, silo } =
      this.args.queryParams;
    if (
      supplier === 'AVEDA_V2' &&
      !this.sessionStorage.getItem('authDetails')
    ) {
      // Save the info from url state to session storage so we can use it later
      this.sessionStorage.setItem(
        'authDetails',
        JSON.stringify({ businessId, branchId, state, supplier })
      );
      const authUrl = ACTIVE_INTEGRATIONS?.[supplier].authUrl;
      const redirectUri = window.location.origin + '/integrations';
      this.window.location.replace(
        `${authUrl}?response_type=code&client_id=pos_phorest_aveda&redirect_uri=${redirectUri}`
      );
      return;
    }
    // The user has logged into supplier and returned we need to update the code and state etc from local storage so we can authenticate

    if (this.sessionStorage.getItem('authDetails') && code) {
      let authDetails = this.sessionStorage.getItem('authDetails');
      try {
        authDetails = JSON.parse(authDetails);
        ({ businessId, branchId, state, supplier } = authDetails);
        this.sessionStorage.removeItem('authDetails');
      } catch (error) {
        console.error(
          'Failed to parse authDetails from session storage:',
          error
        );
        this.error = true;
        this.errorHandler.handle(error);
        this.sessionStorage.removeItem('authDetails');
        throw new Error('Invalid session data');
      }
    }

    if (!code || !state) {
      this.error = true;
      throw new Error('Bad request');
    }
    const supplierCode = ACTIVE_INTEGRATIONS?.[supplier]?.key;
    const path = `/product-ordering-service/business/${businessId}/branch/${branchId}/supplier-account/${supplierCode}/authenticate`;
    let headers = {
      'x-memento-security-context': `${businessId}|${branchId}`,
      accept: 'application/vnd.memento.Auth+json',
      'content-type': 'application/json',
    };
    const { apiGatewayEU, apiGatewayUS } = config.APP;
    let apiGatewayHost = apiGatewayUS;
    if (silo.toLowerCase() === 'eu') {
      apiGatewayHost = apiGatewayEU;
    }

    let url = `${apiGatewayHost}${path}`;

    try {
      const response = yield fetch(`${url}`, {
        method: 'POST',
        body: JSON.stringify({ code, state }),
        headers: {
          ...headers,
        },
      });
      if (response.status !== 200) {
        this.error = true;
      }
    } catch (error) {
      this.error = true;
      this.errorHandler.handle(error);
    }
  }
}
