import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { isEmpty, isPresent } from '@ember/utils';
import { queryManager } from 'ember-apollo-client';
import { dropTask, task, restartableTask } from 'ember-concurrency';
import appointmentForBasketItemQuery from 'my-phorest/gql/queries/appointment-for-basket-item.graphql';
import createBasketMutation from 'my-phorest/gql/mutations/create-basket.graphql';
import checkoutBasketMutation from 'my-phorest/gql/mutations/checkout-basket.graphql';
import updateBasketTipMutation from 'my-phorest/gql/mutations/update-basket-tip.graphql';
import updateBasketItemsDiscount from 'my-phorest/gql/mutations/update-basket-items-discount.graphql';
import updateBasketItemQuantity from 'my-phorest/gql/mutations/update-basket-item-quantity.graphql';
import updateBasketItemPrice from 'my-phorest/gql/mutations/update-basket-item-price.graphql';
import previewCheckoutBasketMutation from 'my-phorest/gql/mutations/preview-basket.graphql';
import AddVoucherToBasket from 'my-phorest/gql/mutations/add-voucher-to-basket.graphql';
import AddProductToBasket from 'my-phorest/gql/mutations/add-product-to-basket.graphql';
import removeBasketItemDiscount from 'my-phorest/gql/mutations/remove-basket-item-discount.graphql';
import RemoveBasketItemFromBasket from 'my-phorest/gql/mutations/remove-basket-item-from-basket.graphql';
import anyEnabledTerminalQuery from 'my-phorest/gql/queries/any-enabled-terminal.graphql';
import {
  Balance,
  PAYMENT_METHODS,
  INTEGRATED_PAYMENT_METHOD_TYPES,
  paymentMethodsLimiter,
  storedCardsLimiter,
} from 'my-phorest/utils/payment-balance';
import { permission } from 'my-phorest/decorators/permission';
import basketQuery from 'my-phorest/gql/queries/basket.graphql';
import paymentMethodsQuery from 'my-phorest/gql/queries/payment-methods.graphql';
import storedCardsQuery from 'my-phorest/gql/queries/stored-cards.graphql';
import { fetchAllPaginatedResults } from 'my-phorest/utils/graphql';
import AddServiceToBasket from 'my-phorest/gql/mutations/add-service-to-basket.graphql';
import { calculateAccountMoneyLimit } from 'my-phorest/utils/payment-balance';
import { A } from '@ember/array';
import { action } from '@ember/object';
import { DISCOUNT_TYPES } from 'my-phorest/utils/discounts';
import ProcessBasketTerminalPayment from 'my-phorest/gql/mutations/process-basket-terminal-payment.graphql';
import CancelBasketCheckout from 'my-phorest/gql/mutations/cancel-basket-checkout.graphql';
import ConvertTerminalPayment from 'my-phorest/gql/mutations/convert-terminal-payment.graphql';
import AppointmentFragment from 'my-phorest/gql/fragments/appointment.graphql';
import {
  evictClientById,
  evictServiceRewardsForClient,
  evictProductRewardsForClient,
} from 'my-phorest/utils/graphql';
import { onErrorCode } from 'my-phorest/decorators/on-error-code';
import { addMoney } from 'my-phorest/utils/currency';
import { shouldNotifySentryAboutCheckoutError } from 'my-phorest/utils/checkout';

const FETCH_ALL_CHUNK_SIZE = 50;

export default class PaymentSlideOverService extends Service {
  @service intl;
  @service notifications;
  @service session;
  @service branchTime;
  @service purchaseBasket;
  @service appointmentSlideOver;
  @service access;
  @service errorHandler;
  @service terminalLoader;
  @service tillLoader;
  @service tillSessions;
  @service swingBridge;

  /** @type import('ember-apollo-client/addon/services/apollo').default */
  @queryManager apollo;

  @tracked basket;
  @tracked balance = new Balance();
  @tracked isSlideOverBlocked = false;
  @tracked returnToSalesEnabled = false;
  currentTotalToPay;
  currentClientId;

  async resetBalanceWhenTotalChanged(basket) {
    if (basket.editedPurchaseId) return;
    const totalSurchargeAmount = this.surchargingEnabled
      ? basket.totalSurchargeAmount.amount
      : 0;
    if (
      addMoney(this.currentTotalToPay, totalSurchargeAmount) !==
      basket.totalToPay.amount
    ) {
      if (this.surchargingEnabled) {
        await this.previewCheckoutBasketMutation.perform(basket.id, []);
      }
      this.balance.reset();
      this.currentTotalToPay = basket.totalToPay.amount;
    }
  }

  @action
  fillAccountMoney(basket) {
    const accountMoneyLimit = calculateAccountMoneyLimit(basket);
    this.balance.setLimitForAccountMoney(accountMoneyLimit);

    if (
      isPresent(basket.refundedPurchaseId) ||
      isPresent(basket.editedPurchaseId)
    ) {
      return;
    }

    let appointments = A(basket.items.map((i) => i.appointment)).compact();

    let appointmentDepositAmount =
      this.appointmentSlideOver.inHouseDepositAmount(appointments);
    this.balance.prePopulateAccountMoney(Number(appointmentDepositAmount));
  }

  @dropTask
  *initializeBalanceTask(basket) {
    const oldBasketId = this.basket?.id;

    this.basket = basket;
    if (oldBasketId !== basket.id) {
      yield this.createNewClientBalance(basket.client);
      this.fillAccountMoney(basket);
      this.currentTotalToPay = basket.totalToPay.amount;
    } else {
      yield this.createNewBalanceWhenClientChangedTask.perform(basket);
    }

    this.fillBalanceFromExistingBasketPayments(basket);
  }

  fillBalanceFromExistingBasketPayments(basket) {
    if (!basket.refundedPurchaseId && !basket.editedPurchaseId) return;
    if (basket.refundedPurchaseId) {
      this.balance.storedCards.forEach((card) => {
        card.disabled = true;
        card.preferred = false;
      });
    }

    if (isEmpty(basket.payments)) return;

    basket.payments.forEach((payment) => {
      let balanceElement;
      if (payment.__typename === 'BasketNonIntegratedPayment') {
        if (payment.paymentType === 'OTHER') {
          balanceElement = this.balance.elements.find(
            (elem) => elem.id === payment.supplementalPaymentTypeId
          );
        } else if (payment.paymentType === 'VOUCHER') {
          balanceElement = this.balance.voucherMethod;
          if (!balanceElement) return;
          let value = basket.editedPurchaseId ? payment.amount.amount : 0;
          balanceElement.useVoucher(value, payment.voucher);
          return;
        } else {
          balanceElement = this.balance.elements.find(
            (elem) => elem.type === payment.paymentType
          );
        }

        if (
          balanceElement &&
          Number(balanceElement.value) === 0 &&
          basket.editedPurchaseId
        ) {
          balanceElement.setValue(payment.amount.amount);
        }
      }

      if (payment.__typename === 'BasketIntegratedPayment') {
        if (payment.feeType === 'STORED_CARD') {
          balanceElement = this.balance.storedCards.find(
            (card) =>
              card.cardType.toLowerCase() ===
                payment.cardDetails?.brand?.toLowerCase?.() &&
              card.cardNumber === payment.cardDetails?.lastFourDigits
          );
          if (balanceElement) {
            balanceElement.integratedPayment = payment;
            if (basket.editedPurchaseId) {
              balanceElement.value = Number(payment.amount.amount).toFixed(2);
            }
            balanceElement.disabled = false;
          }
        } else {
          balanceElement = this.balance.terminalMethod;
          if (balanceElement) {
            balanceElement.addCard({
              integratedPaymentId: payment.id,
              integratedPaymentType: payment.type,
              saveCardDetails: payment.saveCardDetails,
              value: basket.editedPurchaseId
                ? Number(payment.amount.amount).toFixed(2)
                : 0,
              cardDetails: payment.cardDetails,
              feeType: payment?.feeType,
            });
          }
        }
      }
    });
  }

  @dropTask
  *createNewBalanceWhenClientChangedTask(basket) {
    let clientId = basket.client.id;
    if (this.currentClientId !== clientId && !basket.editedPurchaseId) {
      yield this.createNewClientBalance(basket.client);
    }

    this.fillAccountMoney(basket);
  }

  #isValidTerminalForPayment(terminal) {
    return !!terminal.tillId;
  }

  async getCurrentTerminal() {
    if (this.session.terminalId && this.swingBridge.isEmbeddedInSwing) {
      let terminal = await this.terminalLoader.getTerminal.perform(
        this.session.terminalId
      );

      let isValid = this.#isValidTerminalForPayment(terminal);
      if (isValid) {
        return terminal;
      }
    }
    const anyEnabledTerminalResponse = await this.apollo.query({
      query: anyEnabledTerminalQuery,
      variables: {
        branchId: this.session.branchId,
      },
    });
    return anyEnabledTerminalResponse.anyEnabledTerminal;
  }

  @task
  *createBasket(createBasketOptions) {
    const {
      appointmentIds = [],
      clientId,
      courseSessionIds = [],
      packageAppointmentIds = [],
      specialOfferAppointmentIds = [],
      appointmentRewardIds = [],
      products = [],
      services = [],
      vouchers = [],
      courses = [],
      openSales = [],
    } = createBasketOptions;
    const terminalId = yield this.getTerminalId.perform();
    const variables = {
      clientId,
      terminalId,
      staffMemberId: this.session.currentStaffMemberId,
      products,
      services,
      vouchers,
      courses,
      openSales,
    };

    yield this.tillSessions.validateTillSessionTask.perform();

    const appointments = appointmentIds.map((appointmentId) => ({
      appointmentId,
    }));
    if (appointments.length) {
      variables.appointments = appointments;
    }

    const courseSessions = yield Promise.all(
      courseSessionIds.map((appointmentId) =>
        (async () => ({
          appointmentId,
          staffMemberId: await this.getStaffMemberId.perform(appointmentId),
        }))()
      )
    );
    if (courseSessions.length) {
      variables.courseSessions = courseSessions;
    }

    const packageAppointments = yield Promise.all(
      packageAppointmentIds.map((appointmentId) =>
        (async () => ({
          appointmentId,
          staffMemberId: await this.getStaffMemberId.perform(appointmentId),
        }))()
      )
    );
    if (packageAppointments.length) {
      variables.appointmentPackages = packageAppointments;
    }

    const specialOfferAppointments = yield Promise.all(
      specialOfferAppointmentIds.map((appointmentId) =>
        (async () => ({
          appointmentId,
          staffMemberId: await this.getStaffMemberId.perform(appointmentId),
        }))()
      )
    );
    if (specialOfferAppointments.length) {
      variables.appointmentSpecialOffers = specialOfferAppointments;
    }

    const appointmentRewards =
      this.#buildAppointmentServiceRewardsPayload(appointmentRewardIds);
    if (appointmentRewards.length) {
      variables.appointmentServiceRewards = appointmentRewards;
    }

    return this.sendCreateBasketMutation.perform(variables);
  }

  @restartableTask
  *previewCheckoutBasketMutation(basketId, terminalPayments) {
    const variables = {
      basketId,
      integratedPayments: terminalPayments,
    };
    return yield this.apollo.mutate({
      mutation: previewCheckoutBasketMutation,
      variables,
    });
  }

  @task({ enqueue: true, maxConcurrency: 4 })
  *getStaffMemberId(appointmentId) {
    const response = yield this.apollo.query({
      query: appointmentForBasketItemQuery,
      variables: {
        id: appointmentId,
      },
    });

    return response.appointment.staffMemberId;
  }

  @task
  *getTerminalId() {
    const { id: terminalId } = yield this.getCurrentTerminal();

    return terminalId;
  }

  @task
  *createEmptyBasket(options = {}) {
    const terminalId = yield this.getTerminalId.perform();
    let { clientId } = options;

    let variables = {
      terminalId,
      clientId,
      staffMemberId: this.session.currentStaffMemberId,
    };

    yield this.tillSessions.validateTillSessionTask.perform();

    return this.sendCreateBasketMutation.perform(variables);
  }

  @task
  *sendCreateBasketMutation(variables) {
    let { basket } = yield this.apollo.mutate(
      {
        mutation: createBasketMutation,
        variables,
        update: (cache, { data: { createBasket } }) => {
          cache.writeQuery({
            query: basketQuery,
            variables: { id: createBasket.basket.id },
            data: createBasket,
          });
        },
      },
      'createBasket'
    );
    return basket;
  }

  @task
  *addProduct(product, currentBasket) {
    let { basket } = yield this.apollo.mutate(
      {
        mutation: AddProductToBasket,
        variables: {
          input: {
            basketId: currentBasket.id,
            item: product,
          },
        },
      },
      'addProductToBasket'
    );

    this.basket = basket;
  }

  @task
  *addVoucher(newVoucher) {
    yield this.apollo.mutate(
      {
        mutation: AddVoucherToBasket,
        variables: {
          input: {
            basketId: newVoucher.basketId,
            item: {
              serial: newVoucher.serial,
              creditAmount: newVoucher.creditAmount,
              staffMemberId: this.staffMemberForTip,
            },
          },
        },
      },
      'addVoucherToBasket'
    );
  }

  @task
  *increaseQuantity(newItem, currentBasket, currentQuantity) {
    let { basket } = yield this.apollo.mutate(
      {
        mutation: updateBasketItemQuantity,
        variables: {
          input: {
            basketId: currentBasket.id,
            basketItemId: newItem.id,
            quantity: newItem.quantity + currentQuantity,
          },
        },
      },
      'updateBasketItemQuantity'
    );

    this.basket = basket;
  }

  @task
  *updatePrice(item, newPrice) {
    let { basket } = yield this.apollo.mutate(
      {
        mutation: updateBasketItemPrice,
        variables: {
          input: {
            basketId: this.basket.id,
            basketItemId: item.id,
            price: newPrice,
          },
        },
      },
      'updateBasketItemPrice'
    );

    this.basket = basket;
  }

  @task
  *addTip(tip, _basket) {
    let basketArgs = _basket ?? this.basket;
    let { basket } = yield this.apollo.mutate(
      {
        mutation: updateBasketTipMutation,
        variables: {
          basketId: basketArgs.id,
          clientId: basketArgs.client.id,
          tip,
        },
      },
      'updateBasketTip'
    );

    this.basket = basket;
  }

  @task
  *removeTip(basket) {
    yield this.addTip.perform(
      {
        requestedTip: { type: DISCOUNT_TYPES.PERCENT, value: 0 },
      },
      basket
    );
  }

  @dropTask
  @onErrorCode('BASKET_ITEM_DISCOUNT_AMOUNT_EXCEEDS_ITEM_PRICE', {
    *execute() {
      let error = arguments[arguments.length - 1];
      yield this.errorHandler.showErrorNotification(error);
    },
  })
  *updateItemsDiscount(payload) {
    let types = {
      PERCENT: this.updateItemsPercentDiscount,
      AMOUNT: this.updateItemsFlatDiscount,
      FIXED: this.updateItemsFixedDiscount,
    };
    let type = this.#findDiscountType(payload);
    let fn = types[type];

    if (type) {
      yield fn.perform(payload);
    }
  }

  @task
  @permission('discounts.percent')
  *updateItemsPercentDiscount(payload) {
    yield this._mutateItemsDiscount.perform(payload);
  }

  @task
  @permission('discounts.flat')
  *updateItemsFlatDiscount(payload) {
    yield this._mutateItemsDiscount.perform(payload);
  }

  @task
  *updateItemsFixedDiscount(payload) {
    let hasPermission = yield this.access.requirePermission(
      'FIXED_DISCOUNT_ID_' + payload.items[0].fixedDiscount.id
    );
    if (hasPermission) {
      yield this._mutateItemsDiscount.perform(payload);
    }
  }

  @task
  *_mutateItemsDiscount(payload) {
    yield this.apollo.mutate(
      {
        mutation: updateBasketItemsDiscount,
        variables: payload,
      },
      'updateBasketItemsDiscount'
    );
  }

  @task
  *removeItemDiscount(payload) {
    if (Array.isArray(payload.basketItemIds)) {
      for (let id of payload.basketItemIds) {
        yield this.apollo.mutate(
          {
            mutation: removeBasketItemDiscount,
            variables: { basketItemId: id, basketId: payload.basketId },
          },
          'removeBasketItemDiscount'
        );
      }
    } else {
      yield this.apollo.mutate(
        {
          mutation: removeBasketItemDiscount,
          variables: payload,
        },
        'removeBasketItemDiscount'
      );
    }
  }

  @task
  *removeBasketItemFromBasket(basketItem, basket) {
    return yield this.apollo.mutate(
      {
        mutation: RemoveBasketItemFromBasket,
        variables: {
          input: {
            basketId: basket.id,
            basketItemId: basketItem.id,
          },
        },
      },
      'removeBasketItemFromBasket'
    );
  }

  @task
  *addServiceToBasket(basketId, service) {
    yield this.apollo.mutate(
      {
        mutation: AddServiceToBasket,
        variables: {
          input: {
            basketId,
            item: { serviceId: service.id },
          },
        },
      },
      'addServiceToBasket'
    );
  }

  @task
  *triggerTerminalPayment(paymentId, basketId) {
    try {
      yield this.apollo.mutate(
        {
          mutation: ProcessBasketTerminalPayment,
          variables: {
            input: {
              basketId,
              paymentId,
            },
          },
        },
        'processBasketPayment'
      );
    } catch (e) {
      // Error will be propagated to basket.errors which might be displayed later so no need to display notification
      const notifySentry = shouldNotifySentryAboutCheckoutError(e.errors);
      this.errorHandler.handle(e, { showError: false, notifySentry });
    }
  }

  @task
  *cancelBasketCheckout(basket) {
    return yield this.apollo.mutate(
      {
        mutation: CancelBasketCheckout,
        variables: {
          input: {
            basketId: basket.id,
          },
        },
      },
      'cancelBasketCheckout'
    );
  }

  checkoutBasket(basket) {
    let payload = this.balance.preparePayload();
    let variables = {
      basketId: basket.id,
      ...payload,
    };

    if (this.usesTerminalPayment || this.useStoredCardWithTerminal) {
      this.isSlideOverBlocked = true;
    }
    return this.apollo.mutate(
      {
        mutation: checkoutBasketMutation,
        variables,
        update: (cache, { data: { checkoutBasket } }) => {
          let invalidateQueries = [
            'serviceHistory',
            `vouchersByClientId:{"clientId":"${basket.client.id}"}`,
            'voucherBySerial',
            'purchasedProducts',
          ];

          if (
            checkoutBasket.basket.items.some(
              (item) => item.__typename === 'ProductBasketItem'
            )
          ) {
            invalidateQueries.push('products');
          }

          invalidateQueries.forEach((query) => {
            cache.evict({
              id: 'ROOT_QUERY',
              fieldName: query,
            });
          });

          evictClientById(cache, basket.client.id);
          evictServiceRewardsForClient(
            cache,
            basket.client.id,
            this.session.branchList
          );
          evictProductRewardsForClient(
            cache,
            basket.client.id,
            this.session.branchList
          );
          cache.gc();
        },
      },
      'checkoutBasket'
    );
  }

  @dropTask
  *fetchPaymentMethodsTask(client) {
    let methods = fetchAllPaginatedResults(
      this.apollo,
      {
        query: paymentMethodsQuery,
        variables: {
          first: FETCH_ALL_CHUNK_SIZE,
        },
      },
      'paymentMethods'
    );
    let cards = this.apollo.query({
      query: storedCardsQuery,
      variables: {
        clientId: client.id,
      },
      fetchPolicy: 'network-only',
    });

    [methods, cards] = yield Promise.all([methods, cards]);
    let { payment, preselectedCreditCardSaveEnabled } = this.session.branch;
    let { creditCardProvider } = payment;
    this.resetBalance();

    if (client.isWalkIn) {
      preselectedCreditCardSaveEnabled = false;
    }

    methods.unshift({
      archived: false,
      paymentType: PAYMENT_METHODS.TERMINAL,
      builtIn: true,
      saveCardDetails: preselectedCreditCardSaveEnabled,
    });
    let filteredMethods = paymentMethodsLimiter(payment, methods);
    let filteredCards = storedCardsLimiter(payment, cards);
    this.balance.addPaymentMethods(filteredMethods);
    this.balance.addStoredCards(filteredCards, {
      creditCardProvider,
    });
  }

  @dropTask
  *convertTerminalPayment(basketId, paymentId, conversionType, amount) {
    return yield this.apollo.mutate(
      {
        mutation: ConvertTerminalPayment,
        variables: {
          input: {
            basketId,
            paymentId,
            conversionType,
            amount,
          },
        },
      },
      'convertTerminalPayment'
    );
  }

  createNewClientBalance(client) {
    this.currentClientId = client.id;
    return this.fetchPaymentMethodsTask.perform(client);
  }

  resetBalance() {
    this.balance = new Balance();
  }

  addNewStripeCard(card) {
    return this.balance.addNewStripeCard(card);
  }

  @task
  *removeBasketItemByTypesTask(basket, typesToRemove) {
    let itemIdsToRemove =
      basket.items
        ?.filter((item) => {
          return typesToRemove.includes(item.__typename);
        })
        .map((i) => i.id) || [];

    for (let id of itemIdsToRemove) {
      yield this.removeBasketItemFromBasket.perform({ id }, basket);
    }
  }

  #findDiscountType(payload) {
    let [item] = payload.items;
    if (item.fixedDiscount?.id) {
      return DISCOUNT_TYPES.FIXED;
    } else if (item.discount?.type) {
      return item.discount.type;
    }
  }

  #peekStaffMemberIdFromAppointment(appointmentId) {
    let appointment = this.apollo.apollo.client.readFragment({
      id: `Appointment:${appointmentId}`,
      fragment: AppointmentFragment,
      fragmentName: 'appointmentFields',
    });

    return appointment.staffMemberId;
  }

  #buildAppointmentServiceRewardsPayload(appointmentRewardIds) {
    return appointmentRewardIds.map((appointmentId) => {
      return {
        appointmentId,
        staffMemberId: this.#peekStaffMemberIdFromAppointment(appointmentId),
      };
    });
  }

  get manualTippingEnabled() {
    return this.basket.manualTippingEnabled;
  }

  get storedCardPaymentEnabled() {
    return this.session.branch.payment.storedCardPaymentEnabled;
  }

  get selectedMethods() {
    return this.balance.selectedMethods;
  }

  get defaultPaymentMethods() {
    return this.balance.defaultPaymentMethods;
  }

  get customPaymentMethods() {
    return this.balance.customPaymentMethods;
  }

  get surchargingEnabled() {
    return this.session.branch.surchargingEnabled;
  }

  get staffMemberForTip() {
    let basketItems = [...this.basket.items];
    if (basketItems.length > 0) {
      for (let item of basketItems.reverse()) {
        if (item.__typename === 'AppointmentBasketItem') {
          return item.staffMember.id;
        }
      }
    }

    return this.session.currentStaffMemberId;
  }

  get voucherMethod() {
    return this.balance.voucherMethod;
  }

  get usesTerminalPayment() {
    let selectedMethods = this.selectedMethods ?? [];
    return selectedMethods.some(
      (method) =>
        method.type === PAYMENT_METHODS.TERMINAL &&
        method.cards.some((card) => {
          return (
            isEmpty(card.integratedPaymentId) ||
            card.cardDetails?.paymentsPaymentMethodType ===
              INTEGRATED_PAYMENT_METHOD_TYPES.INTERAC_PRESENT
          );
        })
    );
  }

  get hasInteracCardSelected() {
    let selectedMethods = this.selectedMethods ?? [];
    return selectedMethods.some(
      (method) =>
        method.type === PAYMENT_METHODS.TERMINAL &&
        method.cards.some(
          (card) =>
            card.cardDetails?.paymentsPaymentMethodType ===
            INTEGRATED_PAYMENT_METHOD_TYPES.INTERAC_PRESENT
        )
    );
  }

  get terminalMethod() {
    return this.balance.terminalMethod;
  }

  get useStoredCardWithTerminal() {
    let selectedMethods = this.selectedMethods ?? [];
    return selectedMethods.some(
      (method) =>
        method.type === PAYMENT_METHODS.STORED_CARD && method.terminalId
    );
  }
}
