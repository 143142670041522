import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask, task } from 'ember-concurrency';
import { A } from '@ember/array';
import { permission } from 'my-phorest/decorators/permission';
import {
  changeTimeForRelatedAppointments,
  getPreviousValues,
  prepareVariables,
  prepareResourceVariable,
} from 'my-phorest/utils/calendar';
import { AppointmentEvent } from 'my-phorest/utils/calendar-events';
import { queryManager } from 'ember-apollo-client';
import branchAppointmentPromptSettingsQuery from 'my-phorest/gql/queries/branch-appointment-prompt-settings.graphql';
import { variation } from 'ember-launch-darkly';

export default class PersistGapTimeService extends Service {
  @tracked _modalIsOpen = false;
  @tracked calendarJSDropEvent;

  @service appointmentSlideOver;
  @service confirmDialog;
  @service fullCalendar;
  @service intl;
  @service session;
  @service updateAppointments;

  @queryManager apollo;

  get calendarApi() {
    return this.fullCalendar.calendarApi;
  }

  get isModalOpen() {
    return this._modalIsOpen;
  }

  @action
  closeModal() {
    this._modalIsOpen = false;
  }

  @action
  openModal() {
    this._modalIsOpen = true;
  }

  @action
  closeModalAndRefetchResources() {
    this.closeModal();
    this.refetchResources();
  }

  @action
  refetchResources() {
    this.calendarApi.refetchResources();
  }

  @task
  *shouldAskToPersistGapTimeTask(calendarJSDropEvent) {
    let appointments = this.#todayClientAppointments(calendarJSDropEvent);

    const hasAppointmentsWithGapTime =
      appointments.length > 1 &&
      this.#anyServiceHasGapTime(appointments) &&
      this.#eventHasNewTime(calendarJSDropEvent);

    if (!hasAppointmentsWithGapTime) return false;

    const promptSettings =
      yield this.fetchBranchAppointmentPromptSettingsTask.perform();
    return promptSettings.preserveGapTimePromptEnabled;
  }

  @task
  *shouldPersistGapTimeTask(calendarJSDropEvent) {
    let appointments = this.#todayClientAppointments(calendarJSDropEvent);

    const hasAppointmentsWithGapTime =
      appointments.length > 1 &&
      this.#anyServiceHasGapTime(appointments) &&
      this.#eventHasNewTime(calendarJSDropEvent);

    if (hasAppointmentsWithGapTime) {
      const promptSettings =
        yield this.fetchBranchAppointmentPromptSettingsTask.perform();

      const askForMovingAppointment =
        promptSettings.moveAppointmentConfirmationPromptEnabled;
      if (askForMovingAppointment) {
        const confirmed = yield this.confirmDialog.ask({
          title: this.intl.t('appointments.prompts.moving-title'),
          message: this.intl.t('appointments.prompts.moving-description'),
          continue: this.intl.t('global.yes'),
          cancel: this.intl.t('global.no'),
          leadingIcon: 'question-mark-circle',
        });

        if (!confirmed) {
          this.refetchResources();
          return true;
        }
      }

      yield this.updateAllRelatedAppointmentsTask.perform(calendarJSDropEvent);
      return true;
    }
  }

  @action
  prepareVariables(data, relatedAppointments) {
    let variables = prepareVariables(data, this.fullCalendar);
    let additionalAppointments = relatedAppointments;
    let { issues } = variables;
    delete variables.issues;

    if (this.fullCalendar.isMultipleSelection && data.newResource) {
      additionalAppointments = this.#updateResourceForAppointments(
        data.newResource,
        relatedAppointments
      );
    }

    variables = {
      appointments: A([
        {
          ...variables,
        },
        ...additionalAppointments,
      ]).uniqBy('id'),
      issues,
    };
    return variables;
  }

  @action
  updateTrackedAppointments(appointment) {
    if (appointment) {
      this.appointmentSlideOver.upsertAppointmentInTrackedData(
        new AppointmentEvent(appointment)
      );

      this.fullCalendar.selectedAppointments = [
        ...this.appointmentSlideOver.appointments,
      ];
    }
  }

  @task
  *fetchBranchAppointmentPromptSettingsTask() {
    return yield this.apollo.query(
      {
        query: branchAppointmentPromptSettingsQuery,
        variables: {
          branchId: this.session.currentBranch.id,
        },
      },
      'branchAppointmentPromptSettings'
    );
  }

  @dropTask
  @permission('appointments.reschedule', {
    onDeny: function () {
      this.calendarApi.refetchResources();
    },
  })
  *showModalTask(calendarJSDropEvent) {
    this.calendarJSDropEvent = calendarJSDropEvent;
    yield this.openModal();
  }

  @dropTask
  *updateAllRelatedAppointmentsTask(data) {
    let relatedAppointments = changeTimeForRelatedAppointments(
      data,
      this.fullCalendar.calendarApi
    );

    let response = yield this.updateAppointmentsTimeTask.perform(
      data,
      relatedAppointments
    );
    response.appointments?.forEach((appointment) =>
      this.updateTrackedAppointments(appointment)
    );
  }

  @dropTask
  *updateAppointmentsTimeTask(data, relatedAppointments) {
    const variables = this.prepareVariables(data, relatedAppointments);
    const previousValues = getPreviousValues(
      data,
      this.fullCalendar.calendarApi,
      this.fullCalendar.selectedAppointments
    );

    return yield this.updateAppointments.updateAppointmentsTask.perform(
      data,
      variables,
      previousValues
    );
  }

  #anyServiceHasGapTime(appointments) {
    return appointments.some(
      (appointment) => appointment.extendedProps.service?.gapTimeMins > 0
    );
  }

  #todayClientAppointments({ event }) {
    let { clientId, date } = event.extendedProps;
    return this.calendarApi
      .getEvents()
      .filter(
        (appointment) =>
          appointment.extendedProps.clientId === clientId &&
          appointment.extendedProps.date === date
      );
  }

  #eventHasNewTime({ delta }) {
    return delta?.milliseconds !== 0;
  }

  #updateResourceForAppointments(resource, appointments) {
    if (!variation('release-multiple-client-rescheduling')) {
      return appointments;
    }

    return [...appointments, ...this.fullCalendar.selectedAppointments].map(
      (a) => {
        let newResource = {};
        if (this.fullCalendar.selectedAppointmentIds.includes(a.id)) {
          newResource = prepareResourceVariable(resource);
        }
        return {
          id: a.id,
          date: a.date,
          startTime: a.startTime,
          ...newResource,
        };
      }
    );
  }
}
