import Service, { service } from '@ember/service';
import { dropTask, restartableTask } from 'ember-concurrency';
import * as calendarCache from 'my-phorest/utils/calendar-cache';

import appointmentsForClientQuery from 'my-phorest/gql/queries/appointments-for-client.graphql';
import createAppointment from 'my-phorest/gql/mutations/create-appointment.graphql';
import createServiceGroupAppointment from 'my-phorest/gql/mutations/create-service-group-appointment.graphql';
import {
  evictAppointments,
  evictBookingHistory,
  evictClientById,
  evictClientCourses,
  evictServiceRewardsForClient,
} from 'my-phorest/utils/graphql';
import { evictAppointmentsFromResourcesCalendar } from 'my-phorest/utils/calendar-cache';
import { sendCalendarSynchronizationIssueToSentry } from 'my-phorest/utils/calendar';

export default class AppointmentTasksService extends Service {
  @service apollo;
  @service intl;
  @service notifications;
  @service session;

  @dropTask
  *createAppointmentTask(variables = {}) {
    const response = yield this.apollo.mutate({
      mutation: createAppointment,
      variables,
      update: (cache, { data: { createAppointment } }) => {
        const { appointment } = createAppointment;
        if (appointment) {
          calendarCache.addEventsToCalendarCache(cache, appointment);
        }
      },
    });
    return response.createAppointment;
  }

  @dropTask
  *createPackageAppointmentTask(variables = {}) {
    const response = yield this.apollo.mutate({
      mutation: createServiceGroupAppointment,
      variables,
      update: (cache, { data: { createServiceGroupAppointment } }) => {
        const { appointments } = createServiceGroupAppointment;
        if (appointments) {
          calendarCache.addEventsToCalendarCache(cache, appointments);
        }
      },
    });
    return response.createServiceGroupAppointment;
  }

  @restartableTask
  *fetchVisitsTask({ clientId, date }, options = {}) {
    if (!clientId) {
      throw new Error('`clientId` is required to fetch visits');
    }

    if (!date) {
      throw new Error('`date` is required to fetch visits');
    }

    const variables = {
      clientId,
      date,
    };

    const { appointments } = yield this.apollo.query({
      query: appointmentsForClientQuery,
      variables,
      ...options,
    });

    return appointments.edges.map((edge) => edge.node);
  }

  /**
   * Used in "potential Calendar de-synchronization issue" on appointment update attempt
   * when the appointment was deleted before.
   *
   * Removes the calendar cache, informs Sentry about the issue and shows an info notification
   */
  handleDeletedAppointmentUpdateAttempt() {
    sendCalendarSynchronizationIssueToSentry(
      'updating previously removed appointment'
    );
    calendarCache.invalidate(this.apollo.client.cache);
    this.notifications.info(
      this.intl.t(
        'appointments.synchronization.appointment-already-deleted.title'
      ),
      this.intl.t(
        'appointments.synchronization.appointment-already-deleted.description'
      ),
      { sticky: true }
    );
  }

  /**
   * Used in "potential Calendar de-synchronization issue" on appointment deletion attempt
   * when the appointment was deleted or cancelled before.
   *
   * Removes appointment from calendar (and related objects), removes whole calendar cache, and informs Sentry about the issue.
   *
   * @param {Array} appointments
   */
  removePreviouslyDeletedAppointments(appointments) {
    sendCalendarSynchronizationIssueToSentry(
      'removing previously removed/cancelled appointment'
    );
    this.updateCacheAfterAppointmentRemoval(appointments);
    const apolloCache = this.apollo.client.cache;
    calendarCache.invalidate(apolloCache);
  }

  /**
   * Removes appointments from calendar cache and invalidates related queries and objects.
   * It should be used for both: deleted and cancelled appointments as they both result in appointment's removal from the calendar.
   *
   * @param {Array} appointments
   */
  updateCacheAfterAppointmentRemoval(appointments) {
    const cache = this.apollo.client.cache;
    const appointmentIds = appointments.map((appt) => appt.id);
    const clientIds = [...new Set(appointments.map((appt) => appt.client.id))];

    evictAppointments(cache, appointmentIds);
    evictAppointmentsFromResourcesCalendar(cache, appointments);
    evictBookingHistory(cache);

    for (const clientId of clientIds) {
      evictClientCourses(cache, clientId);
      evictClientById(cache, clientId);
      evictServiceRewardsForClient(cache, clientId, this.session.branchList);
    }

    cache.gc();
  }
}
