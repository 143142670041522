import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  refreshAccessToken,
  secondsUntilExpiry,
  jwtDestructure,
} from 'my-phorest/utils/auth';
import {
  INTL_LOCALE_STORAGE_KEY,
  loadTranslations,
  prepareIntlLocale,
} from 'my-phorest/utils/intl';
import * as Sentry from '@sentry/browser';
import { task } from 'ember-concurrency';
import { GraphQLWarning } from 'my-phorest/services/error-handler';
import {
  isPhorestPayEnabled,
  isStripeBranch,
} from 'my-phorest/utils/payment-balance';
import { getTokens } from 'my-phorest/utils/auth';
import { variation } from 'ember-launch-darkly';

// eslint-disable-next-line phorest/moment-import
import moment from 'moment';
import getUserById from 'my-phorest/gql/queries/user.graphql';
import branchField from 'my-phorest/gql/fragments/branch.graphql';

const MIN_SECONDS_BEFORE_RENEW = 5;

function userLocale(user) {
  let { business, branch } = user;
  if (branch && branch.locale) {
    return branch.locale;
  } else {
    return business.locale;
  }
}

class User {
  @tracked accountId;
  @tracked accountList;
  @tracked branchList;
  @tracked id;
  business;
  email;
  staffMembers;

  constructor(user) {
    this.setData(user);
  }

  get account() {
    return this.accountList[this.accountId];
  }

  get avatar() {
    return this.account?.avatar;
  }

  get staffId() {
    return this.account?.id;
  }

  get permissions() {
    return this.account?.accessLevel?.frontendPermissions || [];
  }

  get name() {
    return this.account?.name;
  }

  get firstName() {
    return this.account?.firstName;
  }

  get lastName() {
    return this.account?.lastName;
  }

  get branch() {
    return this.account?.branch;
  }

  get lastClock() {
    return this.account?.lastClock;
  }

  setData(
    { id, email, business, staff = [] },
    { setAccountId } = { setAccountId: true }
  ) {
    this.id = id;
    this.email = email;
    this.business = business;

    this.accountList = {};
    this.branchList = [];
    this.staffMembers = staff;
    staff.forEach((account, index) => {
      let key = account.branch.accountId;
      this.accountList[key] = account;
      this.branchList.push(account.branch);
      if (index === 0 && setAccountId) {
        this.accountId = key;
      }
    });
  }

  setBusiness(business) {
    this.business = business;
  }
}

class GlobalUser {
  @tracked accountId;
  branchList;
  business;
  email;

  constructor({ email, business, branch }) {
    this.accountId = branch.accountId;
    this.branchList = [branch];
    this.business = business;
    this.email = email;
  }

  get branch() {
    return this.branchList?.[0];
  }

  setBusiness(business) {
    this.business = business;
  }
}

class GuestUser {
  constructor() {
    this.id = 'guest-visitor';
    this.firstName = 'Guest';
    this.lastName = 'Visitor';
    this.email = undefined;
    this.business = {
      locale: {
        lang: 'en',
        countryCode: 'US',
      },
    };
    this.branch = {};
  }
}

const guest = new GuestUser();

// Names of keys for local storage
const PHOREST_TOKEN = 'ptoken';
const ACCESS_TOKEN = 'access-token';
const REFRESH_TOKEN = 'refresh-token';
const GLOBAL_INFO = 'global-info';
const TERMINAL_INFO = 'terminal-info';
const PHOREST_USER_ID = 'puser-id';
const PHOREST_ACCOUNT_ID = 'paccount';

export default class SessionService extends Service {
  @service apollo;
  @service apolloSubscriptions;
  @service autoLogout;
  @service eventPropagator;
  @service intl;
  @service('browser/local-storage') localStorage;
  @service router;
  @service recentClients;
  @service routeHistory;
  @service('browser/session-storage') sessionStorage;
  @service tagManager;
  @service('browser/window') window;

  @tracked _branchesList = [];
  @tracked _currentAccountId;
  @tracked _globalInfo;
  @tracked currentUser;

  attemptedTransition;
  refreshTokenPromise = null;

  // used for tracking when a user just logged in. Prevents another check when reloading the browser.
  requireLoginPermissionCheck = false;

  constructor() {
    super(...arguments);

    this.routeHistory.initialize();
    this.restoreGlobalInfo();
  }

  get _user() {
    return this.currentUser || guest;
  }

  get accessToken() {
    return this.localStorage.getItem(ACCESS_TOKEN);
  }

  get accountId() {
    // TODO: https://phorest.jira.com/browse/WT-3632.
    // Spread `session.currentAccountId` usage instead of `session.accountId` and remove this alias.
    return this.currentAccountId;
  }

  get branch() {
    // TODO: https://phorest.jira.com/browse/WT-3632.
    // Spread `session.currentBranch` usage instead of `session.branch` and remove this alias.
    return this.currentBranch;
  }

  get branchesList() {
    return this._branchesList;
  }
  set branchesList(branches) {
    if (branches === null) {
      this._branchesList = [];
    } else if (Array.isArray(branches)) {
      branches.forEach((branch) => {
        const index = this._branchesList.findIndex(
          (_branch) => _branch.id === branch.id
        );

        if (index > -1) {
          // Update branch with new information if already on the list
          this._branchesList[index] = {
            ...this._branchesList[index],
            ...branch,
          };
        } else {
          // Push branch to the list if not on the list yet
          this._branchesList.push(branch);
        }
      });
    }
  }

  get branchId() {
    if (this.isGlobalUser) {
      return this.globalInfo.branchId;
    } else if (this.isTerminal && !this.currentBranch?.id) {
      return this.terminalInfo?.branchId;
    } else {
      return this.currentBranch?.id;
    }
  }

  get branchList() {
    // TODO: https://phorest.jira.com/browse/WT-3632.
    // Rename this to `session.userBranches` as it better reflects that it's not a complete list of branches for given business.
    return this._user.branchList;
  }

  get branchTimeZone() {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const branchTimeZone = this.currentBranch?.timezone;
    return this.currentUser && branchTimeZone
      ? branchTimeZone
      : browserTimeZone;
  }

  get business() {
    // TODO: https://phorest.jira.com/browse/WT-3632.
    // Consider renaming it to `session.currentBusiness` to keep aligned to `session.currentBranch`.
    return this._user.business;
  }

  get businessId() {
    if (this.isGlobalUser) {
      return this.globalInfo.businessId;
    } else {
      const { business_id } = jwtDestructure(this.accessToken);
      return business_id;
    }
  }

  get currentAccountId() {
    return this._currentAccountId;
  }
  set currentAccountId(value) {
    const accountId = typeof value === 'string' ? parseInt(value) : value;

    const accountIdChanged =
      !!this._currentAccountId && this._currentAccountId !== accountId;

    this._currentAccountId = accountId;

    if (this.currentUser) {
      this.currentUser.accountId = accountId;
    }

    if (accountIdChanged) {
      this.eventPropagator.broadcast('branchChange');
    }
  }

  get currentBranch() {
    let branch = this.branchesList.find(
      (branch) => branch.accountId === this.currentAccountId
    );

    let fragment = null;
    if (branch?.id) {
      fragment = this.apollo.client?.cache.readFragment({
        id: `Branch:${branch.id}`,
        fragment: branchField,
      });
    }

    return fragment ?? branch;
  }

  get currentStaffMember() {
    return this.currentUser?.accountList?.[this.currentAccountId] ?? null;
  }

  get currentStaffMemberId() {
    return this.currentStaffMember?.id;
  }

  get currentUserHasAccessToCurrentAccount() {
    if (this.isGlobalUser) return true;
    return !!this.currentUser?.accountList?.[this.currentAccountId];
  }

  get globalInfo() {
    return this._globalInfo;
  }

  set globalInfo(globalInfo) {
    this._globalInfo = globalInfo;

    if (!globalInfo) {
      this.sessionStorage.removeItem(GLOBAL_INFO);
      this.localStorage.removeItem(GLOBAL_INFO);
    } else {
      const stringifiedInfo = JSON.stringify(globalInfo);
      this.sessionStorage.setItem(GLOBAL_INFO, stringifiedInfo);
      this.localStorage.setItem(GLOBAL_INFO, stringifiedInfo);
    }
  }

  get isAuthenticated() {
    return !!this.accessToken;
  }

  get isDACHLocale() {
    let DACHRegions = ['DE', 'AT', 'CH'];
    return DACHRegions.includes(this.locale.countryCode);
  }

  get isGlobalUser() {
    const { client_type } = jwtDestructure(this.accessToken);
    return client_type === 'global';
  }

  get isSingleBranch() {
    return this.business.isSingleBranch;
  }

  get isMultiBranch() {
    return this.business.isMultiBranch;
  }

  get isPhorestPayEnabled() {
    if (!this.currentBranch.payment) {
      return false;
    }
    return isPhorestPayEnabled(this.currentBranch.payment);
  }

  get isStripeBranch() {
    if (!this.currentBranch.payment) {
      return false;
    }
    return isStripeBranch(this.currentBranch.payment);
  }

  get isTerminal() {
    const { client_type } = jwtDestructure(this.accessToken);
    return client_type === 'terminal';
  }

  get lastAccessedAccountId() {
    const value = parseInt(this.localStorage.getItem(PHOREST_ACCOUNT_ID));
    return isNaN(value) ? null : value;
  }

  get lastAccessedUserId() {
    return this.localStorage.getItem(PHOREST_USER_ID);
  }

  get locale() {
    return this.currentBranch?.locale || this.business.locale;
  }

  get pToken() {
    return this.localStorage.getItem(PHOREST_TOKEN);
  }

  get refreshToken() {
    return this.localStorage.getItem(REFRESH_TOKEN);
  }

  get securityScope() {
    let { businessId, branchId, userId } = this;
    branchId = branchId || '';
    userId = userId || '';
    return `${businessId}|${branchId}|${userId}`;
  }

  get silo() {
    if (this.isGlobalUser) {
      return this.globalInfo.silo;
    } else {
      let { silo } = jwtDestructure(this.accessToken);

      return silo;
    }
  }

  get terminalId() {
    if (this.isTerminal) {
      const { user_id } = jwtDestructure(this.accessToken);
      return user_id;
    }
    if (this.isGlobalUser) {
      return this.globalInfo.terminalId;
    }

    return null;
  }

  get terminalInfo() {
    return JSON.parse(this.localStorage.getItem(TERMINAL_INFO));
  }

  get user() {
    const { id, email, name, firstName, lastName, staffId } = this._user;
    return { id, email, name, firstName, lastName, staffId };
  }

  get userId() {
    if (this.isGlobalUser) {
      return null;
    } else if (this.isTerminal) {
      return this.lastAccessedUserId ?? null;
    } else {
      const currentUserId = this.lastAccessedUserId;
      if (currentUserId) return currentUserId;

      const { user_id } = jwtDestructure(this.accessToken);
      return user_id;
    }
  }

  async authenticate(email, password) {
    try {
      let tokens = await getTokens(email, password);
      this.storeTokens(tokens);
      this.forgetLastAccessedUserId();
      this.apollo.reinitClient();
      this.requireLoginPermissionCheck = true;
      return this.isAuthenticated;
    } catch {
      return false;
    }
  }

  clearTokens() {
    [
      ACCESS_TOKEN,
      TERMINAL_INFO,
      PHOREST_TOKEN,
      PHOREST_USER_ID,
      REFRESH_TOKEN,
    ].forEach((token) => {
      this.localStorage.removeItem(token);
    });
    this.globalInfo = null;
  }

  destroySession() {
    this.apolloSubscriptions.unsubscribeAll();
    this.autoLogout.isLocked = false;
    this.branchesList = null;
    this.currentUser = null;
    this.requireLoginPermissionCheck = false;
    this.clearTokens();
    this.apollo.client.stop();
    this.apollo.destroy();
    this.router.transitionTo('login');
    this.tagManager.endSession();
    this.recentClients.clear();
  }

  forgetCurrentUser() {
    this.currentUser = null;
    this.forgetLastAccessedUserId();
  }

  forgetLastAccessedAccountId() {
    this.localStorage.removeItem(PHOREST_ACCOUNT_ID);
  }

  forgetLastAccessedUserId() {
    this.localStorage.removeItem(PHOREST_USER_ID);
  }

  async freshAccessToken() {
    if (!this.isAuthenticated) {
      this.router.transitionTo('logout');
      return;
    }
    let token = this.accessToken;
    if (secondsUntilExpiry(token) > MIN_SECONDS_BEFORE_RENEW) {
      return token;
    } else {
      if (this.refreshTokenPromise) {
        return this.refreshTokenPromise;
      }

      this.refreshTokenPromise = this.renewAccessToken();
      return this.refreshTokenPromise;
    }
  }

  async newGlobalUser(branch) {
    const email = jwtDestructure(this.accessToken).sub;

    this.currentUser = new GlobalUser({
      email,
      branch,
      business: branch.business,
    });
    this.currentAccountId = branch.accountId;
    this.branchesList = [branch];

    await this.updateIntlLocale(this.locale);
  }

  async newUser(user) {
    const newUser = new User(user);
    this.currentUser = newUser;

    /* The current account ID needs to be updated in two scenarios:
        - it wasn't set yet,
        - the new user doesn't have access to it, so we'll update the value with the first branch's account ID
     */
    if (!this.currentAccountId || !newUser.accountList[this.currentAccountId]) {
      this.currentAccountId = newUser.accountId;
    }
    this.branchesList = newUser.branchList;
    await this.updateIntlLocale(userLocale(newUser));
    this.rememberLastAccessedUserId(newUser.id);
    this.verifyUserDataCorrectness(user);

    return newUser;
  }

  rememberLastAccessedAccountId(id) {
    this.localStorage.setItem(PHOREST_ACCOUNT_ID, id);
  }

  rememberLastAccessedUserId(id) {
    this.localStorage.setItem(PHOREST_USER_ID, id);
  }

  async reloadCurrentUserSession() {
    const data = await this.apollo.query(
      {
        query: getUserById,
        variables: {
          id: this.userId,
        },
        fetchPolicy: 'network-only',
      },
      'user'
    );
    this.currentUser.setData(data, { setAccountId: false });
    this.branchesList = this.currentUser.branchList;
  }

  async renewAccessToken() {
    try {
      let newTokens = await refreshAccessToken(this.refreshToken);
      this.storeTokens(newTokens);
      return newTokens.accessToken;
    } catch (error) {
      this.router.transitionTo('logout');
    } finally {
      this.refreshTokenPromise = null;
    }
  }

  @task
  *renewAccessTokenTask() {
    return yield this.renewAccessToken();
  }

  storeTokens({ accessToken, refreshToken, pToken, globalInfo, terminalInfo }) {
    const { localStorage } = this;
    const setItem = (key, value) => {
      if (value) {
        localStorage.setItem(key, value);
      }
    };

    setItem(ACCESS_TOKEN, accessToken);
    setItem(REFRESH_TOKEN, refreshToken);
    setItem(PHOREST_TOKEN, pToken);

    if (globalInfo) {
      this.globalInfo = globalInfo;
    }
    if (terminalInfo) {
      setItem(TERMINAL_INFO, JSON.stringify(terminalInfo));
    }
  }

  async switchUser(user) {
    const currentBranchId = this.branchId;

    const newUser = await this.newUser(user);

    const { lastAccessedAccountId } = this;
    if (lastAccessedAccountId && !newUser.accountList[lastAccessedAccountId]) {
      this.router.transitionTo('accounts');
      return;
    } else if (currentBranchId) {
      const currentBranch = this.branchesList.find(
        (b) => b.id === currentBranchId
      );

      if (currentBranch) {
        const { accountId } = currentBranch;
        this.rememberLastAccessedAccountId(accountId);
        this.currentAccountId = accountId;
        newUser.accountId = accountId;
      }
    }

    this.currentUser = newUser;
    this.tagManager.endSession();
  }

  async updateIntlLocale(locale) {
    const locales = prepareIntlLocale(locale);

    await loadTranslations(this.intl, locales);
    this.intl.setLocale(locales);
    moment.locale(locale.lang);

    this.localStorage.setItem(INTL_LOCALE_STORAGE_KEY, JSON.stringify(locales));
  }

  verifyUserDataCorrectness(user) {
    if (Array.isArray(user?.staff)) {
      user.staff.forEach((staffMember) => {
        if (typeof staffMember.branch.accountId !== 'number') {
          Sentry.withScope(function (scope) {
            scope.setLevel('warning');
            scope.setExtra('businessId', user.business.id);
            scope.setExtra('branchId', staffMember.branch.id);
            scope.setExtra('userId', user.id);
            scope.setExtra('staffMemberId', staffMember.id);
            const error = new GraphQLWarning(
              "branch doesn't have accountId defined"
            );
            error.name += ' | user data correctness';

            Sentry.captureException(error);
          });
        }
      });
    }
  }

  addAuthTokenChangeListener() {
    if (!variation('ops-handle-auth-token-changes')) return;
    if (this.authTokenChangeListener) return;
    this.authTokenChangeListener = this.handleAccessTokenChange.bind(this);
    this.window.addEventListener('storage', this.authTokenChangeListener, {
      passive: true,
    });
  }

  removeAuthTokenChangeListener() {
    this.window.removeEventListener('storage', this.authTokenChangeListener);
    this.authTokenChangeListener = null;
  }

  /**
   * Detect access-token changes triggered from other sessions.
   * When access-token has been removed, the user is logged out.
   * When a new access-token has been created, the window is reloaded
   * so the user is logged in this session as well.
   *
   * @param {Event} event storage event
   */
  async handleAccessTokenChange(event) {
    if (event.storageArea != localStorage) return;
    if (event.key === ACCESS_TOKEN) {
      if (!event.newValue) {
        // Access token has been removed, we log this session out
        this.router.transitionTo('logout');
      } else if (event.newValue && !event.oldValue) {
        this.window.location.reload();
      }
    }
  }

  /**
   * Restore the global user info from browser storage when reloading or creating a new window.
   * When the current tab is just reloaded, the info is still present in the SessionStorage.
   * When a new window is opened, the info is restored from LocalStorage.
   */
  restoreGlobalInfo() {
    if (this.isGlobalUser) {
      if (this._globalInfo) return;
      if (this.sessionStorage.getItem(GLOBAL_INFO)) {
        this._globalInfo = JSON.parse(this.sessionStorage.getItem(GLOBAL_INFO));
      } else if (this.localStorage.getItem(GLOBAL_INFO)) {
        this.sessionStorage.setItem(
          GLOBAL_INFO,
          this.localStorage.getItem(GLOBAL_INFO)
        );
        this._globalInfo = JSON.parse(this.localStorage.getItem(GLOBAL_INFO));
      }
    }
  }
}
