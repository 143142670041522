import Service, { service } from '@ember/service';
import { tracked } from 'tracked-built-ins';
import {
  fromJSDate as dateFromJSDate,
  addMonths,
} from 'my-phorest/utils/local-date-helpers';

export default class BreakSlideOverService extends Service {
  @service fullCalendar;
  @service intl;
  @service session;

  @tracked break;
  @tracked date;
  @tracked isOpen = false;
  @tracked showPaidBreak;

  open(date, resource, { showPaidBreak }) {
    this.isOpen = true;
    this.date = date;
    this.resource = resource;
    this.showPaidBreak = showPaidBreak;
    this.break = tracked(this.defaults);
  }

  close() {
    this.isOpen = false;
  }

  get defaults() {
    return {
      label: this.intl.t('break-slide-over.default-break-label'),
      durationMin: 30,
      isPaid: !!this.session.business.staffMembersSettings?.breakPaidByDefault,
      repeatOptions: {
        enabled: false,
        untilDate: addMonths(dateFromJSDate(this.date), 12 * 3),
      },
      applyToOtherStaff: false,
    };
  }

  get isStaffMemberBreak() {
    return this.resource?.__typename === 'Staff';
  }

  otherStaffMembers() {
    if (!this.isStaffMemberBreak || !this.fullCalendar.calendarApi) return [];

    const staffMembersInCalendar = this.fullCalendar.calendarApi
      .getResources()
      .map((staff) => staff.extendedProps)
      .sort((a, b) => a.name.localeCompare(b.name));

    return staffMembersInCalendar.filter((staff) => {
      return staff.id !== this.resource.id;
    });
  }
}
