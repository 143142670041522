import Service, { service } from '@ember/service';
import config from 'my-phorest/config/environment';

export default class TagManagerService extends Service {
  @service('browser/window') window;
  @service session;
  @service swingBridge;
  @service electronApp;

  get currentlyUsingVariable() {
    if (this.swingBridge.isEmbeddedInSwing) {
      return 'embedded-in-swing';
    } else if (this.electronApp.isRunningInElectron) {
      return 'electron';
    } else {
      return 'web';
    }
  }

  get dataLayer() {
    return this.window.dataLayer || { push: () => {} };
  }

  get employeeName() {
    let { firstName, lastName } = this.session.user;
    return [firstName, lastName].filter((name) => !!name).join(' ');
  }

  get isEnabled() {
    return config.tagManager.enabled;
  }

  get localeString() {
    let { lang, countryCode } = this.session.locale;

    if (lang && countryCode) {
      return `${lang}-${countryCode}`.toLowerCase();
    } else if (lang) {
      return `${lang}`.toLowerCase();
    } else {
      return 'en';
    }
  }

  get metadata() {
    let branchFields = {};
    let { deployRevision: eventVersion } = config.APP;
    let { employeeName } = this;
    let { business, branch, user } = this.session;

    if (branch) {
      branchFields = {
        eventAccountId: branch.accountId,
        eventBranchId: branch.id,
        eventBranchName: branch.shortName,
      };
    }
    return {
      eventVersion,
      eventIsEmbeddedInBrowser: false,
      eventVisitorId: user.id,
      eventLocale: this.localeString,
      eventBusinessId: business.id,
      eventBusinessName: business.name,
      eventEmployeeEmail: user.email,
      eventEmployeeName: employeeName,
      eventCurrentlyUsing: this.currentlyUsingVariable,
      ...branchFields,
    };
  }

  endSession() {
    if (!this.isEnabled) return;
    this.dataLayer.push({ event: 'user-session-ended' });
  }

  push(data) {
    if (!this.isEnabled) return;
    this.dataLayer.push({ ...data, ...this.metadata });
  }
}
