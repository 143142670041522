import EmberRouter from '@embroider/router';
import config from 'my-phorest/config/environment';
import { service } from '@ember/service';

export default class Router extends EmberRouter {
  @service('browser/window') window;

  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('login');
  this.route('logout');
  this.route('forgot-password');
  this.route('reset-password');
  this.route('auto-login-v1', { path: 'auto-login/v1' });
  this.route('auto-login-v2', { path: 'auto-login/v2' });
  this.route('pin-pad');
  this.route('accounts', { path: '/a' }, function () {
    this.route('account', { path: '/:account_id' }, function () {
      this.route('appointments', function () {
        this.route('add-deposit', function () {
          this.route('payment-in-progress', {
            path: '/payment-in-progress/:basket_id',
          });
        });
        this.route('treat-card');
        this.route('notes', { path: ':client_id/notes' });
        this.route('edit', { path: ':appointment_id/edit' }, function () {
          this.route('staff-member');
          this.route('service', function () {
            this.route('swap-package');
          });
          this.route('machine');
          this.route('room');
        });
        this.route(
          'group-bookings',
          { path: ':appointment_id/group-booking' },
          function () {
            this.route('client');
            this.route('payment', { path: ':group_booking_id/payment' });
            this.route('staff-member');
            this.route('service');
          }
        );

        this.route('rebook');

        this.route('planner', { resetNamespace: true }, function () {
          this.route('clients');
          this.route('booking-history', function () {
            this.route('photos');
          });
          this.route('staff');
          this.route('services');
          this.route('availability');
          this.route('edit-booking-intent');
        });

        this.route('waitlist', { resetNamespace: true }, function () {
          this.route('booking', { path: ':booking_id' }, function () {
            this.route('add-service');
            this.route('select-staff');
          });
          this.route('new');
        });

        this.route('breaks', function () {
          this.route('edit', { path: '/:break_id/edit' });
        });

        this.route('booking-history', function () {
          this.route('photos');
        });
        this.route('no-show', function () {});
        this.route('staff-qualification-checker', function () {
          this.route('services');
          this.route('staff-availability');
        });
      });

      this.route('print', function () {
        this.route('appointment-reminder', {
          path: '/appointment-reminder/:id',
        });
        this.route('client-quote', {
          path: '/client-quote/:client_id/:date',
        });
        this.route('columns');
        this.route('selected-note', {
          path: '/selected-note/:client_id/:date/:branch_id',
        });
        this.route('appointment-client-travel-card', {
          path: '/appointment-client-travel-card/:appointment_id',
        });
        this.route('voucher-receipt');
      });

      this.route('purchase', function () {
        this.route('receipt', { path: '/:purchase_id/receipt' });

        this.route('new', function () {
          this.route('select-client');
          this.route('petty-cash', function () {
            this.route('new');
          });

          this.route('client', function () {
            this.route('new');
          });
        });
        this.route('create-basket');
        this.route('basket', { path: '/:basket_id' }, function () {
          this.route('payment-methods', function () {
            this.route('use-voucher');
            this.route('add-tip');
          });
          this.route('client-rewards-slide-over', { path: '/loyalty' });
          this.route('payment-in-progress');
          this.route('payment-completed');
          this.route('select-client');

          this.route('petty-cash', function () {
            this.route('new');
          });

          this.route('client', function () {
            this.route('new');
          });
          this.route('notes');
          this.route('product-history');
          this.route('add-card');
        });
      });
      this.route('cash-up');
      this.route('cash-up-report-viewer', {
        path: '/cash-up-report-viewer/:till_id/:till_session_id',
      });
      this.route('clients');
      this.route('client.create', { path: '/clients/create' });
      this.route('client', { path: '/clients/:id' }, function () {
        this.route('overview');
        this.route('booking-history', function () {
          this.route('notes');
          this.route('photos');
        });
        this.route('courses');
        this.route('memberships');
        this.route('patch-tests');
        this.route('product-history', function () {
          this.route('notes');
        });
        this.route('treat-card');
        this.route('vouchers');
        this.route('spend');
        this.route('wallet', function () {
          this.route('add-card');
          this.route('card-added');
        });
        this.route('communications');
        // ** START Remove with variation release-consultations-streamline-navigation-from-client-card **
        this.route('consultations');
        this.route('form', function () {
          this.route('app', function () {
            this.route('mirror', { path: '/:form_id' });
          });
        });
        // ** END **
        this.route('consultations-embed', function () {
          this.route('forms');
          this.route('plans');
          this.route('gallery');
          this.route('markup');
          this.route('comparison');
          this.route('uploads');
        });
        this.route('photos');
      });
      this.route('audit-trail', { path: '/trail/:type/:id' });
      this.route('clients.audit-trail', { path: '/clients/audit-trail' });
      this.route('products.audit-trail', { path: '/products/audit-trail' });
      this.route('audit-trail-breaks');
      this.route('commission-systems');
      this.route('commission-system.create', {
        path: '/commission-systems/create',
      });
      this.route('commission-system', { path: '/commission-systems/:id' });
      this.route('chain-courses');
      this.route('chain-course.create', { path: '/chain-courses/create' });
      this.route('chain-course', { path: '/chain-courses/:id' }, function () {
        this.route('details');
        this.route('branches');
      });
      this.route('courses', function () {
        this.route('courses');
        this.route('client-courses');
      });
      this.route('course.create', { path: '/courses/create' });
      this.route('course', { path: '/courses/:id' });
      this.route('client-courses');
      this.route('client-course.create', { path: '/client-courses/create' });
      this.route('client-course', { path: '/client-courses/:id' }, function () {
        this.route('details');
        this.route('history');
      });
      this.route('chain-packages');
      this.route('chain-package.create', { path: '/chain-packages/create' });
      this.route('chain-package', { path: '/chain-packages/:id' }, function () {
        this.route('details');
        this.route('branches');
      });
      this.route('reports');
      this.route('jasper-reports');
      this.route('jasper-reports.history', { path: '/jasper-reports/history' });
      this.route('jasper-reports.history-of-report', {
        path: '/jasper-reports/history/:id',
      });
      this.route('packages');
      this.route('package.create', { path: '/packages/create' });
      this.route('package', { path: '/packages/:id' });
      this.route('products');
      this.route('product.create', { path: '/products/create' });
      this.route('product', { path: '/products/:id' }, function () {
        this.route('details');
        this.route('history');
      });
      this.route('chain-products');
      this.route('chain-product.create', { path: '/chain-products/create' });
      this.route('chain-product', { path: '/chain-products/:id' }, function () {
        this.route('details');
        this.route('branches');
      });
      this.route('chain-memberships');
      this.route('chain-membership.create', {
        path: '/chain-memberships/create',
      });
      this.route(
        'chain-membership',
        { path: '/chain-memberships/:id' },
        function () {
          this.route('details');
          this.route('branches');
        }
      );
      this.route('memberships', function () {
        this.route('memberships');
        this.route('client-memberships');
        this.route('billing', function () {
          this.route('due', function () {
            this.route('add-card');
          });
          this.route('failed', function () {
            this.route('add-card');
          });
          this.route('success');
        });
      });
      this.route('membership.create', { path: '/memberships/create' });
      this.route('membership', { path: '/memberships/:id' });
      this.route(
        'client-membership',
        { path: '/client-memberships/:id' },
        function () {
          this.route('details');
          this.route('history');
        }
      );
      this.route('chain-special-offers', function () {
        this.route('services');
        this.route('products');
      });
      this.route('chain-special-offer', function () {
        this.route('product.create', { path: '/products/create' });
        this.route('product', { path: '/products/:id' }, function () {
          this.route('details');
          this.route('branches');
        });
        this.route('service.create', { path: '/services/create' });
        this.route('service', { path: '/services/:id' }, function () {
          this.route('details');
          this.route('branches');
        });
      });
      this.route('special-offers', function () {
        this.route('services');
        this.route('products');
      });
      this.route('special-offer', function () {
        this.route('product.create', { path: '/products/create' });
        this.route('product', { path: '/products/:id' });
        this.route('service.create', { path: '/services/create' });
        this.route('service', { path: '/services/:id' });
      });
      this.route('fixed-discounts');
      this.route('fixed-discount.create', { path: '/fixed-discounts/create' });
      this.route('fixed-discount', { path: '/fixed-discounts/:id' });
      this.route('tax-rates', function () {
        this.route('service');
        this.route('product');
        this.route('prepaid');
      });
      this.route('chain-settings');
      this.route('tax-rate.create', { path: '/tax-rates/:type/create' });
      this.route('tax-rate', { path: '/tax-rates/:id' });
      this.route('legacy-report-viewer', { path: '/report-viewer' });
      this.route('multi-format-legacy-report-viewer', {
        path: '/multi-format-report-viewer',
      });
      this.route('manager');
      this.route('manager-business', { path: '/manager/business' });
      this.route('manager-categories', { path: '/manager/categories' });
      this.route('manager-chain-library', { path: '/manager/chain-library' });
      this.route('manager-consultations', { path: '/manager/consultations' });
      this.route(
        'manager-departments',
        { path: '/manager/departments' },
        function () {
          this.route('assign');
        }
      );
      this.route('manager-petty-cash', { path: '/manager/petty-cash' });
      this.route('orders');
      this.route('order.create', { path: '/orders/create' });
      this.route('order', { path: '/orders/:id' });
      this.route('order.add-items', { path: '/orders/:id/add-items' });
      this.route('stock-takes');
      this.route('stock-take-report-viewer', {
        path: '/stock-take-report-viewer/:stock_take_id',
      });
      this.route('stock-take', { path: '/stock-takes/:id' });
      this.route('stock-transfers', function () {
        this.route('incoming');
        this.route('outgoing');
      });
      this.route('stock-transfer', { path: '/stock-transfers/:id' });
      this.route('suppliers');
      this.route('supplier.create', { path: '/suppliers/create' });
      this.route('supplier', { path: '/suppliers/:id' });
      this.route('client-merge');
      this.route('csv-export');
      this.route('marketing', function () {
        this.route('tools', { path: '/' });
        this.route('client-reconnect', function () {
          this.route('index', { path: '/' }, function () {
            this.route('overview');
            this.route('settings');
            this.route('templates', function () {
              this.route(
                'category',
                { path: 'category/:category_id' },
                function () {
                  this.route('template', { path: '/template/:name' });
                }
              );
            });
          });
          this.route(
            'clients',
            {
              path: '/:category_id/clients',
            },
            function () {
              this.route('status', { path: '/*status_type' });
            }
          );
        });
        this.route('meta-ads', function () {
          this.route('campaigns', function () {
            this.route('list');
            this.route('new', { path: '/:meta_connection_id/new' });
            this.route('edit', {
              path: '/:meta_connection_id/:campaign_id/edit',
            });
            this.route('roi', {
              path: '/:campaign_id/roi',
            });
          });
          this.route('get-started');
        });
        this.route('loyalty', function () {
          this.route('referrals', function () {
            this.route('dashboard');
            this.route('settings');
            this.route('clients');
          });
          this.route('referrals-landing');
          this.route('moments', function () {
            this.route('num-of-visits');
          });
        });
        this.route('treatcard', function () {
          this.route('rewards', function () {
            this.route('services', function () {
              this.route('new');
              this.route('edit', { path: '/:id/edit' });
            });
            this.route('products', function () {
              this.route('new');
              this.route('edit', { path: '/:id/edit' });
            });
          });
          this.route('settings');
        });
        this.route('welcome-email', function () {
          this.route('email-editor');
          this.route('settings');
        });
        this.route('lead-management', function () {
          this.route('leads');
          this.route('settings');
          this.route('lead-engagement', function () {
            this.route('template', { path: '/:channel' });
          });
        });
        this.route('client-export');
        this.route('client-export.audit-trail', {
          path: '/client-export/audit-trail',
        });
      });
      this.route('payroll-reports', function () {
        this.route('new');
        this.route('report', { path: '/:id' });
        this.route('business', function () {
          this.route('new');
          this.route('report', { path: '/:id' });
        });
      });
      this.route('sales', function () {
        this.route('petty-cash', function () {
          this.route('new');
          this.route('edit', { path: '/:id/edit' });
        });
        this.route('till-transactions');
        this.route('till-floats');
      });
      this.route('vouchers', function () {
        this.route('client-vouchers');
        this.route('voucher-campaigns');
      });
      this.route('client-voucher.create', { path: '/client-voucher/create' });
      this.route(
        'client-voucher',
        { path: '/client-vouchers/:id' },
        function () {
          this.route('details');
          this.route('history');
        }
      );
      this.route('voucher-campaign.create', {
        path: '/voucher-campaign/create',
      });
      this.route(
        'voucher-campaign',
        {
          path: '/voucher-campaigns/:id',
        },
        function () {
          this.route('details');
        }
      );
      this.route('embed', function () {
        this.route('app', function () {
          this.route('mirror', { path: '/*embedded_path' });
        });
      });
      this.route('noaccess');

      if (config.launchDarkly.mode === 'local') {
        this.route('feature-flags');
      }

      this.route('settings', function () {
        this.route('client-notifications', function () {
          this.route('index', { path: '/' });
          this.route('appointment-confirmation', function () {
            this.route('template', { path: '/:channel' });
            this.route('settings');
          });
          this.route('appointment-reminder', function () {
            this.route('template', { path: '/:channel' });
            this.route('settings');
            this.route('reminder-history');
          });
          this.route('manual-reminders', {
            path: '/appointment-reminder/manual-reminders',
          });
          this.route('rebook-appointment', function () {
            this.route('sms');
            this.route('settings');
          });
          this.route('no-show', function () {
            this.route('template', { path: '/:channel' });
            this.route('settings');
          });
          this.route('treatcard-referral', function () {
            this.route('sms');
            this.route('settings');
          });
          this.route('care-instructions', function () {
            this.route('new');
            this.route('edit', { path: '/:id' });
          });
        });
      });
      this.route('cash-up-report', { path: '/cash-up-report/:till_id' });
      this.route('dot-phrase-library', { path: '/dotphrases' }, function () {
        this.route('new');
        this.route('edit', { path: '/:id' });
      });
      this.route('brand-settings');
    });
  });
  this.route('styleguide');
  this.route('pgo', function () {
    this.route('fbe-auth');
    this.route('login');
  });

  this.route('customer');
  this.route('customer-children', { path: '/customer/*path' });

  this.route('download');

  this.route('404', { path: '/*path' });
  this.route('integrations');
});
