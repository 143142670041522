import { cancel, later } from '@ember/runloop';
import Service from '@ember/service';
import { service } from '@ember/service';
import { dropTask, enqueueTask, timeout } from 'ember-concurrency';
import { tracked } from 'tracked-built-ins';
import timeoutForEnv from 'my-phorest/utils/timeout-for-env';
import { BREAKPOINT_SM, isBelowBreakpoint } from 'my-phorest/utils/breakpoints';

export const NOTIFICATION_LIFETIME = 3000;
export const TYPE_SUCCESS = 'success';
export const TYPE_FAILURE = 'failure';
export const TYPE_INFO = 'info';

export class Notification {
  @tracked isShowing;
  props = {};
  type = '';
  @tracked sticky = false;

  constructor(type, props, sticky) {
    this.type = type;
    this.props = props;
    this.isShowing = true;
    this.sticky = sticky;
  }

  get isSuccess() {
    return this.type === TYPE_SUCCESS;
  }

  get isFailure() {
    return this.type === TYPE_FAILURE;
  }

  get isInfo() {
    return this.type === TYPE_INFO;
  }
}

export default class NotificationsService extends Service {
  #notifications = tracked([]);
  #leaveCountdownID = null;

  @service('browser/window') window;

  get all() {
    return this.#notifications;
  }

  get count() {
    return this.#notifications.length;
  }

  get firstNotification() {
    if (this.count === 0) return null;
    return this.#notifications[0];
  }

  success(title, description, options = {}) {
    this.#createNotification(TYPE_SUCCESS, title, description, options);
  }

  failure(title, description, options = {}) {
    this.#createNotification(TYPE_FAILURE, title, description, options);
  }

  info(title, description, options = {}) {
    this.#createNotification(TYPE_INFO, title, description, options);
  }

  #createNotification(type, title, description, options = {}) {
    const notification = new Notification(
      type,
      {
        title,
        description,
      },
      options.sticky
    );

    this.pushNotificationTask.perform(notification);
  }

  @enqueueTask()
  *pushNotificationTask(notification) {
    const enteringDelayIsNeeded = this.count === 0;

    if (enteringDelayIsNeeded) {
      /* @oisin: On Mobile - The time between the save bar disappearing and the
       * saved toast message popping up should be delayed a bit so that the
       * saved toast message doesn’t appear under the purple bar */
      const startingDelay = isBelowBreakpoint(BREAKPOINT_SM) ? 600 : 100;

      yield timeout(timeoutForEnv(startingDelay));
    }

    this.#pushNotification(notification);
  }

  #pushNotification(notification) {
    this.#notifications.push(notification);
    this.#startCountdownIfNeeded();
  }

  #startCountdownIfNeeded() {
    const { firstNotification } = this;
    if (!firstNotification) return;

    const mustBeClosedManually = Boolean(firstNotification.sticky);
    if (mustBeClosedManually) return;

    const countdownHasStarted = Boolean(this.#leaveCountdownID);
    if (countdownHasStarted) return;

    // When testing the notification after some action, like clicking a button,
    // it is necessary to not await for the promise of this initial action.
    // Ember knows about this countdown, so awaiting for the initial action will
    // also include this duration.
    // Instead, please use something like this:
    //
    // const settledPromise = click('#some-action-initiator');
    // await waitFor('[data-test-notification]');
    // assert.dom('[data-test-notification-tile]').hasText('Some message');
    // await settledPromise;
    this.#leaveCountdownID = later(
      this,
      () => {
        this.removeNotification.perform(firstNotification);
      },
      timeoutForEnv(NOTIFICATION_LIFETIME, 100)
    );
  }

  @dropTask
  *removeNotification(notification) {
    notification.isShowing = false;

    // This is meant to give time for the leave animation to finish.
    // See app/components/ui/notification/index.hbs
    yield timeout(timeoutForEnv(300));

    if (notification === this.firstNotification) {
      cancel(this.#leaveCountdownID);
      this.#leaveCountdownID = null;

      this.#notifications.shift();
      this.#startCountdownIfNeeded();
      return;
    }

    const index = this.#notifications.indexOf(notification);
    this.#notifications.splice(index, 1);
  }

  @dropTask
  *removeAllNotifications() {
    for (const notification of this.#notifications) {
      notification.isShowing = false;
    }

    // This is meant to give time for the leave animation to finish.
    // See app/components/ui/notification/index.hbs
    yield timeout(timeoutForEnv(300));

    this.#notifications.length = 0;
    cancel(this.#leaveCountdownID);
    this.#leaveCountdownID = null;
  }
}
