import Modifier from 'ember-modifier';
import { service } from '@ember/service';
import { registerDestructor } from '@ember/destroyable';
import {
  importTinymce,
  addAttribution,
  addPersonaliseButton,
  addLinkButton,
  addLinksButton,
  addCustomLinkButton,
  wireEditorEventsToInputEvent,
  handleContentEditableAttributeInMacros,
  importSanitizeHTML,
} from 'my-phorest/utils/tinymce';
import { action } from '@ember/object';
import { assert } from '@ember/debug';
import * as Sentry from '@sentry/browser';

export default class RichTextEditorModifier extends Modifier {
  @service intl;
  @service session;
  @service spellchecker;

  macros;
  links;
  onLinkSelectAction;
  facebookUrl;
  onCustomLinkSelectAction;
  editor;
  attributionSVG;
  onInput;

  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, this.destroyEditor);
  }

  get isGermanSpeakingBranch() {
    return this.session.locale.lang === 'de';
  }

  get isOnlineBookingEnabled() {
    return this.session.branch?.onlineBookingEnabled;
  }

  async modify(
    element,
    positional,
    {
      options,
      macros,
      links,
      $attribution,
      onInput,
      onLinkSelectAction,
      facebookUrl,
      onCustomLinkSelectAction,
      sanitizeHTMLOnPaste,
    }
  ) {
    const $element = element;
    const tinymce = await importTinymce();

    const sanitizeHTML = sanitizeHTMLOnPaste
      ? await importSanitizeHTML()
      : (h) => {
          return h;
        };

    await this.spellchecker.injectWebSpellChecker();

    if ($attribution) {
      this.attributionSVG = $attribution.outerHTML;
    }

    this.macros = macros;
    this.facebookUrl = facebookUrl;
    this.onInput = onInput;
    this.links = links;
    this.onLinkSelectAction = onLinkSelectAction;
    this.onCustomLinkSelectAction = onCustomLinkSelectAction;

    tinymce.init({
      selector: `#${$element.id}`,
      setup: this._handleSetup.bind(this),
      init_instance_callback: (editor) => {
        editor.__isLoaded = true;
        if (editor.iframeElement) {
          if (this.spellchecker.doesWebSpellCheckerExists) {
            window.WEBSPELLCHECKER.init({
              container: editor.iframeElement,
            });
          } else {
            Sentry.captureException(new Error('WEBSPELLCHECKER not defined'));
          }
        }

        this.editor = editor;
      },
      paste_preprocess: (editor, args) => {
        if (editor && args) {
          try {
            args.content = sanitizeHTML(args.content);
          } catch (e) {
            console.error(e);
          }
        }
      },
      ...options,
    });
  }

  @action
  destroyEditor() {
    this.editor?.destroy(false);
  }

  _handleSetup(editor) {
    assert(
      'An attribution icon must be provided to TinyMCE. This is a licensing requirement.',
      this.attributionSVG
    );

    addAttribution(editor, this.attributionSVG);
    addCustomLinkButton(editor, this.intl, this.onCustomLinkSelectAction);

    if (this.macros) {
      this.#setupMacros(editor);
      addPersonaliseButton(editor, this.macros, this.intl);
    }

    if (this.links) {
      if (this.links.length > 1) {
        this.#setupLinks(editor);
        addLinksButton(editor, this.links, this.intl, this.onLinkSelectAction);
      } else {
        addLinkButton(
          editor,
          this.links[0],
          this.intl,
          this.onLinkSelectAction
        );
      }
    }

    if (this.onInput) {
      editor.on('input', this.onInput);
    }

    wireEditorEventsToInputEvent(editor);
    handleContentEditableAttributeInMacros(editor);

    editor.__isSetup = true;
  }

  getImage(editor, url) {
    const content = `<img href="#" src="${url}" />`;
    editor.insertContent(content);
    editor.dispatch('input');
  }

  #setupMacros(editor) {
    this.macros = this.macros?.filter(
      (macro) =>
        (macro === 'salutation' && this.isGermanSpeakingBranch) ||
        macro !== 'salutation'
    );

    this.macros = this.macros.map((macro) => {
      if (macro === 'branch-logo') {
        return {
          name: macro,
          enabled: !!this.session.branch.branchLogoUrl,
          onAction: () => {
            this.getImage(editor, this.session.branch.branchLogoUrl);
          },
        };
      }
      return {
        name: macro,
        enabled: true,
      };
    });
  }

  #setupLinks() {
    this.links = this.links.map((link) => {
      if (link === 'facebook-link') {
        return {
          link: link,
          enabled: !!this.facebookUrl,
        };
      }
      return {
        link: link,
        enabled: true,
      };
    });
  }
}
